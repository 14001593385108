import { CoursesServices } from "Helpers/api/Courses";
import { ICourseMain } from "Types/courses";
import { useMutation, useQueryClient } from "react-query";

const coursesListKey = ["courses", "list"];

export const useCoursesOrder = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (courses: ICourseMain[]) =>
      CoursesServices.sortCourses(courses.map((i) => i.id)),
    onMutate: (variables) => {
      const prevData = queryClient.getQueryData(coursesListKey);

      queryClient.setQueryData(coursesListKey, variables);

      return prevData;
    },
    onError: async () => {
      await queryClient.refetchQueries(coursesListKey);
    },
  });

  return { mutate, isLoading };
};
