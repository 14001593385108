import {
  Button,
  Classes,
  Dialog,
  FileInput,
  FormGroup,
  HTMLSelect,
  InputGroup,
  TextArea,
} from "@blueprintjs/core";
import { useError } from "Hooks/common";
import { useAppSelector } from "Hooks/redux";
import { FC, useMemo, useState } from "react";
import { ICourseMainInfo } from "Types/courses";
import { CourseMainInfoItem } from "./courseMainInfoItem";
import { TСourseStatus } from "Types/common";

import styles from "./index.module.css";
import {
  useAddEditMainInfo,
  useDeleteMainInfo,
} from "Hooks/api/courses/mainInfo";
import { useParams } from "react-router-dom";

interface IMainInfos {
  courseMainInfos: ICourseMainInfo[];
  selectedRegion: number;
}

export interface IEditDataMainInfo {
  id?: number;
  title: string;
  img: string | File;
  description: string;
  status: TСourseStatus;
  regionId: number;
  streamDate?: string;
  bannerUrl?: string;
}

type TFilter = TСourseStatus | "all";

export const MainInfos: FC<IMainInfos> = ({
  courseMainInfos,
  selectedRegion,
}) => {
  const [filter, setFilter] = useState<TFilter>("active");
  const onChangeFilter: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setFilter(e.target.value as TFilter);
  };
  const mainInfoRegion = useMemo(() => {
    let mainInfo = courseMainInfos
      .filter((item) => item.regionId === selectedRegion)
      .sort((a, b) => (a.status > b.status ? 1 : -1));

    // if (mainInfo.length === 0) {
    //   mainInfo = courseMainInfos
    //     .filter((item) => item.regionId === 1 && item.status === "active")
    //     .sort((a, b) => (a.status > b.status ? 1 : -1));
    // }

    if (filter === "all") {
      return mainInfo;
    }

    return mainInfo.filter((item) => item.status === filter);
  }, [courseMainInfos, selectedRegion, filter]);

  const { id: courseId } = useParams<string>();

  const regions = useAppSelector((s) => s.App.regions);

  const onClickCloseModal = () => {
    setEditData(undefined);
    setErrors([]);
  };

  const {
    setErrors,
    errors,
    isDisabled: isDisabledSave,
    mutate,
  } = useAddEditMainInfo(onClickCloseModal);

  const error = useError(errors);

  const [editData, setEditData] = useState<undefined | IEditDataMainInfo>();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (editData) {
      setEditData({ ...editData, [e.target.id]: e.target.value });
    }
  };

  const onChangeText: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (editData) {
      setEditData({ ...editData, description: e.target.value });
    }
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (editData) {
      setEditData({ ...editData, [e.target.id]: e.target.value });
    }
  };

  const onChangeFile: React.FormEventHandler<HTMLInputElement> = (e) => {
    if (editData) {
      setEditData({ ...editData, img: e.currentTarget.files?.[0] || "" });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (editData && courseId) {
      mutate({ courseId, data: editData });
    }
  };

  const imgPathDefault = useMemo(
    () =>
      courseMainInfos.find(
        (item) => item.status === "active" && item.regionId === 1
      )?.img || "",
    [courseMainInfos]
  );

  const onClickAddMainInfo = () => {
    const initialDataMainInfo: IEditDataMainInfo = {
      title: "",
      img: imgPathDefault,
      description: "",
      status: "active",
      regionId: selectedRegion,
    };
    setEditData(initialDataMainInfo);
  };

  const { isDisabled: isDisabledDelete, mutate: deleteMainInfo } =
    useDeleteMainInfo(onClickCloseModal);

  const onClickDelete = () => {
    if (editData && editData.id && courseId) {
      deleteMainInfo({ courseId, id: editData.id });
    }
  };

  const isDisabled = useMemo(
    () => isDisabledSave || isDisabledDelete,
    [isDisabledSave, isDisabledDelete]
  );

  return (
    <div>
      <div className="row">
        <h2>
          Main Info <Button icon={"add"} minimal onClick={onClickAddMainInfo} />
        </h2>
        <HTMLSelect
          value={filter}
          onChange={onChangeFilter}
          iconProps={{ icon: "filter" }}
        >
          <option value={"active"}>Active</option>
          <option value={"draft"}>Draft</option>
          <option value={"closed"}>Closed</option>
          <option value={"all"}>All</option>
        </HTMLSelect>
      </div>
      <div className={styles["grid"]}>
        {mainInfoRegion.map((item, index) => (
          <CourseMainInfoItem
            {...item}
            key={"mainInfo" + index}
            setEditData={setEditData}
          />
        ))}
      </div>
      <Dialog
        isOpen={!!editData}
        title={
          editData?.id ? "Edit Main Info #" + editData.id : "Add Main info"
        }
        onClose={onClickCloseModal}
      >
        <form onSubmit={onSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label={"Title"} {...error("title")} labelFor={"title"}>
              <InputGroup
                disabled={isDisabled}
                value={editData?.title || ""}
                onChange={onChange}
                id={"title"}
                {...error("title")}
              />
            </FormGroup>
            <FormGroup label={"Image"} {...error("img")} labelFor={"img"}>
              <FileInput
                fill
                id="img"
                onInputChange={onChangeFile}
                hasSelection={!!editData?.img}
                text={
                  typeof editData?.img === "object"
                    ? editData.img.name
                    : editData?.img
                    ? editData.img === imgPathDefault
                      ? editData.img.split("/").pop() + " (Default image)"
                      : editData.img.split("/").pop()
                    : "Choose file"
                }
                disabled={isDisabled}
              />
            </FormGroup>
            <FormGroup
              label={"Description"}
              {...error("description")}
              labelFor={"description"}
            >
              <TextArea
                growVertically={true}
                onChange={onChangeText}
                value={editData?.description}
                fill
                disabled={isDisabled}
                {...error("description")}
              />
            </FormGroup>
            <FormGroup
              label={"streamDate"}
              {...error("streamDate")}
              labelFor={"streamDate"}
            >
              <InputGroup
                disabled={isDisabled}
                value={editData?.streamDate || ""}
                onChange={onChange}
                id={"streamDate"}
                {...error("streamDate")}
              />
            </FormGroup>
            <FormGroup
              label={"bannerUrl"}
              {...error("bannerUrl")}
              labelFor={"bannerUrl"}
            >
              <InputGroup
                disabled={isDisabled}
                value={editData?.bannerUrl || ""}
                onChange={onChange}
                id={"bannerUrl"}
                {...error("bannerUrl")}
              />
            </FormGroup>
            <FormGroup
              label={"Status"}
              {...error("status")}
              labelFor={"status"}
            >
              <HTMLSelect
                id="status"
                fill
                value={editData?.status}
                onChange={onSelect}
                disabled={isDisabled}
                {...error("status")}
              >
                <option value={"active"}>Active</option>
                <option value={"draft"}>Draft</option>
                <option value={"closed"}>Closed</option>
              </HTMLSelect>
            </FormGroup>
            <FormGroup
              label={"Region"}
              {...error("regionId")}
              labelFor={"regionId"}
            >
              <HTMLSelect
                id="regionId"
                {...error("regionId")}
                fill
                value={editData?.regionId.toString()}
                onChange={onSelect}
                disabled={isDisabled}
              >
                {regions.map((item, index) => (
                  <option value={item.id} key={"langMainInfo" + index}>
                    {item.title}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {editData?.id && (
                <Button
                  onClick={onClickDelete}
                  intent={"danger"}
                  type={"button"}
                  disabled={isDisabled}
                  icon={"trash"}
                >
                  Delete
                </Button>
              )}
              <Button icon={"saved"} type={"submit"} disabled={isDisabled}>
                {editData?.id ? "Save" : "Add Main Info"}
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};
