import { FC } from "react";

import { Button, Card, Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { Status } from "Componens/common";

import { URL_COURSES_ID } from "Constants/URL";

import { CoursesItemProps } from "./types";

import classNames from "classnames";
import styles from "./styles.module.css";

export const CoursesItem: FC<CoursesItemProps> = ({
  id,
  regionOnly,
  status,
  title,
  dragElement,
}) => {
  return (
    <Card className={classNames(styles["card"], styles["flex"])}>
      <div className={styles["flex"]}>
        <div {...dragElement} className={styles["dragItem"]}>
          <Icon icon="drag-handle-vertical" />
        </div>
        <div className={styles["flex"]}>
          <div className={styles["title"]}>ID: {id}</div>
          <div>{title}</div>
        </div>
      </div>
      <div className={styles["flex"]}>
        <div className={styles["status"]}>
          <Status status={status} />
        </div>
        <div>
          <Link to={URL_COURSES_ID(id)}>
            <Button icon={"arrow-right"} intent={"none"} minimal />
          </Link>
        </div>
      </div>
    </Card>
  );
};
