import { FC, useState } from "react";
import { useCourseList } from "Hooks/api/courses";
import { useCoursesOrder } from "Hooks/api/courses/coursesOrder";

import { Button } from "@blueprintjs/core";
import { Loading } from "Componens/common";
import { ModalMainCourse } from "Componens/courses/modalMainCourse";
import { CoursesItem } from "Componens/courses/coursesItem";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import {
  IAddCourse,
  initialDataAddCourse,
} from "Componens/courses/modalMainCourse/constants";

import styles from "Styles/pageStyles/list.module.css";

export const CoursesListPage: FC = () => {
  const { data, isLoading } = useCourseList();

  const { isLoading: isPendingSort, mutate } = useCoursesOrder();

  const [editData, setEditData] = useState<undefined | IAddCourse>(undefined);

  const onOpenCloseModal = () => {
    setEditData(initialDataAddCourse);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !data || isLoading || isPendingSort) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newList = Array.from(data);
    const [removed] = newList.splice(sourceIndex, 1);
    newList.splice(destinationIndex, 0, removed);

    mutate(newList);
  };

  if (isLoading) {
    return <Loading fullScreen />;
  }

  return (
    <div className={styles["list"]}>
      <div className={styles["row"]}>
        <h1>Courses</h1>
        <Button intent={"primary"} icon={"add"} onClick={onOpenCloseModal}>
          Add Course
        </Button>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {data?.map((item, index) => (
                <Draggable
                  key={item.id + "cousrse"}
                  draggableId={item.id.toString() + index}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={styles["item"]}
                    >
                      <CoursesItem
                        {...item}
                        dragElement={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ModalMainCourse
        isOpen={!!editData}
        data={editData}
        setData={setEditData}
      />
    </div>
  );
};
